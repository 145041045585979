import * as React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
<Layout>
<SEO
title="404: Not found"
description=""
keyword=""
site_name=""
url=""
og_title=""
og_type=""
og_image=""
tw_cardType=""
tw_userName=""
/>
<div className="g-container">
<main className="g-pageMain" role="main">
<div className="l-container">
<div className="u-mt-x5">
<h1>404: Not Found</h1>
<p>You just hit a route that doesn&#39;t exist... the sadness.</p>
</div>
<div className="u-mt-x5 u-ta-right">
<p className="c-pageTop" data-js-localscroll><a href="#top"><img className="i-label i-label-left" src="/assets/img/icons/ic_tri_up.svg" alt="" />ページトップ</a></p>
</div>
</div>{/* /.l-container */}
</main>{/* /.g-pageMain */}
</div>{/* /.g-container */}
</Layout>
)

export default NotFoundPage
